@import "../../../../../../Project/Camfil/code/ui/styles/Camfil/imports";

$name: '.toasterComponent';

#{$name} {
    position: fixed;
    max-width: 72ch;
    transition: all .85s ease-in-out;
    color: $white;
    background-color: $primary;
    background-image: radial-gradient(circle at center center, black, #ccc),repeating-radial-gradient(circle at center center, black, black, 2rem, transparent 4rem, transparent 4rem);
    background-blend-mode: multiply;
    box-shadow: $box-shadow-lg;
    border: 0;
    z-index: $zindex-popover;

    @include media-breakpoint-down(lg) {
        bottom: 2rem;
        right: 1rem;
        width: calc(100% - 2rem);
    }

    @include media-breakpoint-up(lg) {
        width: 580px;
        bottom: 2rem;
        right: 2rem;
    }

    &--hidden {
        opacity: 0;
        right: -100% !important;
    }

    &-experienceEditorWrapper {
        bottom: 2rem;
        right: 2rem;
        position: fixed;
        z-index: $zindex-fixed;
        min-height: 5rem;
        min-width: 7rem;
    }

    &-close {
        position: absolute;
        top: 0;
        right: 0;
        color: inherit;

        &:hover {
            color: inherit;
        }
    }

    .card-body {
        position: relative;
        color: inherit;
    }

    &-webinarIconWrap {
        position: absolute;
        transform: translateY(-50%);
        top: 0;
        left: 1.5rem;
        display: flex;
        align-items: center;
    }

    &-webinarIcon {
        background-color: $sand-light;
        color: $primary;
        border-radius: 50%;
        padding: 1.25rem;
        width: 90px;
        height: 90px;
        box-shadow: $box-shadow;

        svg {
        }
    }


    &-person {
        position: absolute;
        transform: translateY(-50%);
        top: 0;
        left: 1.5rem;
        display: flex;
        align-items: center;
        width: calc(100% - 120px);
    }

    &-personImgContainer {
        margin-right: -1rem;
        flex-shrink: 0;
        aspect-ratio: 1;
        position: relative;
        height: 90px;
        width: auto;
        box-shadow: $box-shadow;
        border-radius: 50%;
    }

    &-personImg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    &-personContent {
        background-color: $white;
        box-shadow: $box-shadow;
        background-color: $white;
        padding: 1rem;
        padding-left: 3rem;
        margin-left: -1rem;
        width: 100%;
        border-radius: 0 $border-radius $border-radius 0;
        color: $black;
    }

    &-personContentName {
        font-weight: bold;
    }
}
